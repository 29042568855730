import React, { useContext, useState } from 'react';
import styles from './style.module.scss';
import { useIsMobile } from 'hooks/use-size-class';
import { buildClassName } from 'utils/build-class-name';
import Button from 'components/control/button';
import ExpandIcon3 from 'components/icon/expand-icon-3';
import { useRouter } from 'next/router';

interface MapCardProps {
  trackMapCardClick?: () => void;
}

export default function MapButtonCard({ trackMapCardClick }: MapCardProps) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const handleOnClick = () => {
    router.push('/search');
    trackMapCardClick();
  };
    
  return (
    <div className={buildClassName(styles['card-wrapper'], isMobile && styles['mobile-card-wrapper'])} onClick={handleOnClick}>
      <Button
        theme={'secondary'}
        Icon={ExpandIcon3}
        label='View Map'
        className={styles.button}
      />
    </div>
  );
}
